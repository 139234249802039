import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './App.css';

function App() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [sessionName, setSessionName] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [jwtToken, setJwtToken] = useState('');
    const [numbers, setNumbers] = useState('');
    const [message, setMessage] = useState('');
    const [file, setFile] = useState(null);
    const [sending, setSending] = useState(false);
    const [scheduleTime, setScheduleTime] = useState('');
    const [repeatInterval, setRepeatInterval] = useState('');
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [loadingQR, setLoadingQR] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const [messageCount, setMessageCount] = useState(0);
    const [messageLimit, setMessageLimit] = useState(0);  // New state for message limit
    
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            // Fetch the message count
            axios.get('https://moderately-relaxing-squirrel.ngrok-free.app/message-count', {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                setMessageCount(response.data.messageCount);
            })
            .catch(err => {
                console.error('Error fetching message count:', err);
            });

            // Fetch the message limit for the authenticated user
            axios.get('https://moderately-relaxing-squirrel.ngrok-free.app/message-limit', {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                console.log('Message Limit Response:', response.data);  // Check if limit is correct
                setMessageLimit(response.data.messageLimit); // Set message limit in the frontend
            })
            .catch(err => {
                console.error('Error fetching message limit:', err);
            });
        }
    }, [loggedIn]);

    // Handle user registration
    const handleRegister = async () => {
        try {
            const { data } = await axios.post('https://moderately-relaxing-squirrel.ngrok-free.app/register', { username, password });
            localStorage.setItem('token', data.token);
            setSessionName(data.sessionName);
            setRegisterSuccess(true);
            alert('Registration successful. Please scan the QR code to complete login.');
            generateQRCode(data.sessionName);
        } catch (error) {
            console.error('Registration error:', error);
            alert('Registration failed');
        }
    };

    // Handle user login
    const handleLogin = async () => {
        try {
            setLoggingIn(true);
            const { data } = await axios.post('https://moderately-relaxing-squirrel.ngrok-free.app/login', { username, password });
            localStorage.setItem('token', data.token);
            setSessionName(data.sessionName);
            setLoggedIn(true);
            alert('Login successful');
            navigate('/send-message');
        } catch (error) {
            console.error('Login error:', error);
            alert('Login failed');
        } finally {
            setLoggingIn(false);
        }
    };

    // Generate QR Code
    const generateQRCode = async (session) => {
        setLoadingQR(true);
        try {
            const response = await axios.get(`https://moderately-relaxing-squirrel.ngrok-free.app/generate-qr?sessionName=${session}`);
            setQrCode(response.data.qrCode);
        } catch (error) {
            console.error('QR Code error:', error);
        } finally {
            setLoadingQR(false);
        }
    };

    // Handle sending message
    const handleSendMessage = async () => {
        // Check if 'numbers' field is empty
        if (!numbers.trim()) {
            alert('Please enter valid numbers.');
            return;
        }

        // Check if at least one of 'message' or 'file' is provided
        if (!message.trim() && !file) {
            alert('Please enter a message or upload a file.');
            return;
        }

        const deviceId = "your-unique-device-id"; // Replace with actual logic to get the device ID

        setSending(true);

        const formData = new FormData();
        formData.append('deviceId', deviceId);
        formData.append('username', username);  // Assuming `username` is available in state
        formData.append('numbers', JSON.stringify(numbers.split('\n').map(num => num.trim())));  // Split numbers
        formData.append('message', message);
        formData.append('scheduleTime', scheduleTime);
        formData.append('repeatInterval', repeatInterval);

        if (file) {
            formData.append('file', file);
        }

        try {
            const token = localStorage.getItem('token');
            await axios.post('https://moderately-relaxing-squirrel.ngrok-free.app/send-message', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            });
            alert('Message sent successfully!');
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Failed to send message.');
        } finally {
            setSending(false);
        }
    };

    return (
        <div className="app">
            {/* User authentication section */}
            {!loggedIn ? (
                <div className="auth-section">
                    <h1>{registerSuccess ? 'Scan QR Code' : 'Register / Login'}</h1>
                    {!registerSuccess ? (
                        <>
                            <input
                                type="text"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button onClick={handleRegister}>Register</button>
                            <button onClick={handleLogin} disabled={loggingIn}>
                                {loggingIn ? 'Logging in...' : 'Login'}
                            </button>
                        </>
                    ) : (
                        <>
                            {loadingQR ? (
                                <p>Generating QR...</p>
                            ) : qrCode ? (
                                <div>
                                    <img src={qrCode} alt="QR Code" />
                                    <p>Scan the QR code to log in.</p>
                                </div>
                            ) : null}
                        </>
                    )}
                </div>
            ) : (
                <div className="send-message-form">
                    <h1>Send WhatsApp Messages</h1>
                    {/* Display the message count and message limit */}
                    <p>Messages sent: {messageCount} / {messageLimit}</p> {/* Message count and limit */}
                    <textarea
                        placeholder="Enter numbers separated by new lines"
                        value={numbers}
                        onChange={(e) => setNumbers(e.target.value)}
                    />
                    <textarea
                        placeholder="Enter message here..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <input
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                    <input
                        type="datetime-local"
                        value={scheduleTime}
                        onChange={(e) => setScheduleTime(e.target.value)}
                    />
                    <select value={repeatInterval} onChange={(e) => setRepeatInterval(e.target.value)}>
                        <option value="">No Repeat</option>
                        <option value="1m">Every 1 Minute</option>
                        <option value="5m">Every 5 Minutes</option>
                        <option value="10m">Every 10 Minutes</option>
                        <option value="15m">Every 15 Minutes</option>
                        <option value="1h">Every 1 Hour</option>
                        <option value="1d">Every 1 Day</option>
                        <option value="1M">Every 1 Month</option>
                        <option value="1y">Every 1 Year</option>
                    </select>
                    <button onClick={handleSendMessage} disabled={sending || !numbers.trim() || (!message.trim() && !file)}>
                        {sending ? 'Sending...' : 'Send Message'}
                    </button>
                </div>
            )}
        </div>
    );
}

export default App;
